import { GraphQLClient } from "graphql-request";

const endpoint = "https://api.webwiebe.nl/graphql";
const client = new GraphQLClient(endpoint);
process.env["NODE_TLS_REJECT_UNAUTHORIZED"] = "0";

export const login = async (
  username: string,
  password: string
): Promise<{
  success: boolean;
  token?: string;
  error?: string;
}> => {
  const query = `
    mutation Login($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        token
      }
    }
  `;

  try {
    const data = await client.request<{ login: { token: string } }>(query, {
      username,
      password,
    });

    return {
      success: true,
      token: data?.login?.token,
    };
  } catch (error) {
    console.error(error);
    // Handle error
    error = (error as any)?.response?.errors
      ?.map((e: { message: string }) => e.message)
      .join("\n");

    return {
      success: false,
      error: (error as string) || "An unknown error occurred",
    };
  }
};

export const register = async (
  username: string,
  email: string,
  password: string
): Promise<{
  success: boolean;
  token?: string;
  error?: string;
}> => {
  const query = `
    mutation Register($username: String!, $email: String!, $password: String!) {
      register(username: $username, email: $email, password: $password) {
        token
      }
    }
  `;

  try {
    const data = await client.request<{ register: { token: string } }>(query, {
      username,
      email,
      password,
    });

    return {
      success: true,
      token: data?.register?.token,
    };
  } catch (error) {
    console.error(error);
    // Handle error
    error = (error as any)?.response?.errors
      ?.map((e: { message: string }) => e.message)
      .join("\n");

    return {
      success: false,
      error: (error as string) || "An unknown error occurred",
    };
  }
};
