import React, { useState, useEffect } from "react";
import "./MenuBar.css";
import AuthModal from "./AuthModal";

function MenuBar(): JSX.Element {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState<"login" | "register">("login");

  useEffect(() => {
    // Check if the user is logged in
    // Set the value of isLoggedIn accordingly
    const userToken = sessionStorage.getItem("userToken");
    setIsLoggedIn(!!userToken);
  }, []);

  const handleLogin = () => {
    // Handle login logic
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Handle logout logic
    sessionStorage.removeItem("userToken");
    setIsLoggedIn(false);
  };

  const handleModalOpen = (mode: "login" | "register") => {
    setIsModalOpen(true);
    setMode(mode);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);

    const userToken = sessionStorage.getItem("userToken");
    setIsLoggedIn(!!userToken);
  };

  return (
    <>
      <div className="menu-bar">
        {isLoggedIn ? (
          <button className="menu-button" onClick={handleLogout}>
            Logout
          </button>
        ) : (
          <>
            <button className="menu-button" onClick={() => handleModalOpen("login")}>
              Login
            </button>
            <button className="menu-button" onClick={() => handleModalOpen("register")}>
              Register
            </button>
          </>
        )}
      </div>
      <AuthModal isOpen={isModalOpen} onClose={handleModalClose} mode={mode} onLogin={handleLogin} onLogout={handleLogout} />
    </>
  );
}

export default MenuBar;