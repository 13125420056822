import React, { useState } from "react";
import { login } from "./client/webwiebe-api";
import Spinner from "./Spinner";

interface LoginFormProps {
  onClose: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    const loginResult = await login(username, password);
    setLoading(false);

    if (loginResult.success && loginResult.token) {
      sessionStorage.setItem("userToken", loginResult?.token);
      setErrorMessage("");

      onClose();
      return
    }

    if (loginResult.success === false || loginResult.error){ 
      console.warn( "could not login using these credentials: ", { loginResult })
      setErrorMessage("Could not login using these credentials.");
    }
  };

  if(loading){
    return (
      <Spinner />
    )
  }

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <label>
        Username:
        <input type="text" value={username} onChange={handleUsernameChange} />
      </label>
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
      </label>
      <button type="submit">Login</button>
    </form>
  );
};

export { LoginForm };
