import React from "react";
import "./Spinner.css";

type SpinnerProps = {};

function Spinner({}: SpinnerProps): JSX.Element {
  return (
    <div className="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner