import React from "react";
import "./App.css";
import Logo from "./Logo";
import MenuBar from "./MenuBar";

function App(): JSX.Element {
  return (
    <div className="App">
      <MenuBar />
      <Logo />
    </div>
  );
}

export default App;
