import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const Logo = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const menuBar = document.querySelector(".menu-bar")!;
    const menuHeight =  menuBar ? menuBar.clientHeight : 0
    const renderer = new THREE.WebGLRenderer({ canvas });
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      canvas.clientWidth /(canvas.clientHeight - menuHeight),
      0.1,
      1000,
    );

    const geometry = new THREE.BoxGeometry();

    const material = new THREE.ShaderMaterial({
      uniforms: {
        color1: { value: new THREE.Color(0xff0000) },
        color2: { value: new THREE.Color(0x00ff00) },
        color3: { value: new THREE.Color(0x0000ff) },
        color4: { value: new THREE.Color(0xffff00) },
        color5: { value: new THREE.Color(0xff00ff) },
        color6: { value: new THREE.Color(0x00ffff) },
      },
      vertexShader: `
          varying vec3 vNormal;
          void main() {
            vNormal = normal;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
          }
        `,
      fragmentShader: `
          varying vec3 vNormal;
          uniform vec3 color1;
          uniform vec3 color2;
          uniform vec3 color3;
          uniform vec3 color4;
          uniform vec3 color5;
          uniform vec3 color6;
          void main() {
            vec3 gradientColor = vec3(1.0, 1.0, 1.0);
            if (vNormal.x > 0.0) {
              gradientColor *= color1;
            } else if (vNormal.x < 0.0) {
              gradientColor *= color2;
            }
            if (vNormal.y > 0.0) {
              gradientColor *= color3;
            } else if (vNormal.y < 0.0) {
              gradientColor *= color4;
            }
            if (vNormal.z > 0.0) {
              gradientColor *= color5;
            } else if (vNormal.z < 0.0) {
              gradientColor *= color6;
            }
            gl_FragColor = vec4(gradientColor, 1.0);
          }
        `,
    });
    const cube = new THREE.Mesh(geometry, material);
    scene.add(cube);

    // Add wireframe lines to the cube
    const wireframe = new THREE.WireframeGeometry(geometry);
    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
    const lines = new THREE.LineSegments(wireframe, lineMaterial);
    cube.add(lines);

    // Add a point light to the scene
    const light = new THREE.PointLight(0xffffff, 1, 10);
    light.position.set(0, 0, 10);
    scene.add(light);

    camera.position.z = 5;

    const animate = () => {
      requestAnimationFrame(animate);
      cube.rotation.x += 0.01;
      cube.rotation.y += 0.01;
      renderer.render(scene, camera);
    };

    animate();

    // Resize canvas to window size
    const resizeHandler = () => {
      const menuBar = document.querySelector(".menu-bar")!;
      const menuHeight =  menuBar ? menuBar.clientHeight : 0
      const width = window.innerWidth;
      const height = window.innerHeight - menuHeight;

      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };
    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return <canvas ref={canvasRef} />;
};

export default Logo;
