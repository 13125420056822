import React, { useState } from "react";
import "./AuthModal.css";
import { LoginForm } from "./LoginForm";
import { RegistrationForm } from "./RegistrationForm";

type AuthModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: "login" | "register";
  onLogin: () => void;
  onLogout: () => void;
};

function AuthModal({ isOpen, onClose, mode }: AuthModalProps): JSX.Element {
  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-overlay" onClick={onClose} />
      <div className="modal-content">
        <h2>{mode === "login" ? "Login" : "Register"}</h2>
        {mode === "login" ? (
          <LoginForm onClose={onClose} />
        ) : (
          <RegistrationForm onClose={onClose} />
        )}
      </div>
    </div>
  );
}

export default AuthModal;
